import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SettingContext } from "../App";
import env from "react-dotenv";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



const WelcomePage = () => {
    const { state } = React.useContext(SettingContext);
    const iaam = state.iaam
    const [rows, setRows] = useState([]);
    const [createSatus, setCreateStatus] = useState(true);

    const logout = () => {
        state.iaam.end_session()
    }

    const [activeTab, setActiveTab] = React.useState('get');

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const token = await iaam.getAccessToken()
            const option = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }
            const data = await iaam.fetch(`${env.API_URL}/patient/get`, option)
            // const json = await data.json();
            const rows = data.map((row,index) => {return {...row, id:index}})
            setRows(rows)
        }

        if(state.isAuthenticated){
            fetchUsers()
        }
    }, [state.isAuthenticated])

    const columns = [
        {   field: 'person_id', 
            headerName: 'เลขบัตรประชาชน', 
            width: 200,
        },
        { field: 'firstname', headerName: 'ชื่อ', width: 150 },
        { field: 'lastname', headerName: 'นามสกุล', width: 180,},
        { field: 'birthdate', headerName: 'วันเกิด', width: 150 },
        { field: 'gender', headerName: 'เพศ', width: 150 },
        { field: 'disease_id', headerName: 'รหัสโรค', width: 180 },
        { field: 'email', headerName: 'อีเมล์', width: 200 },
        { field: 'i_prov_code', headerName: 'รหัสจังหวัด', width: 200 },
        { field: 'i_amp_code', headerName: 'รหัสอำเภอ', width: 200 },
    ]

    const onSubmit = async() => {
        const token = await iaam.getAccessToken()
            const option = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }
            const resp = await iaam.fetch(`${env.API_URL}/patient/create`, option)
            if(resp?.error){
                setCreateStatus(false)
            }
            else setCreateStatus(true)
            setOpen(true);
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="bg-white">
            <div className="relative isolate px-6 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="font-primary mx-auto max-w-2xl pt-32 pb-20">
                    
                    <div className="text-center">
                        <Typography variant="p" className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            {env.CLIENT_NAME}
                        </Typography>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            สวัสดี {state.profile.displayName}
                        </p>
                        <Typography className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            {state.profile.org_name}
                        </Typography>
                        <Typography className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            {state.profile.mail}
                        </Typography>
                        
                    </div>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href="#"
                            onClick={logout}
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            ออกจากระบบ
                        </a>
                        <a href="https://iaamreg.iaam.cloud" className="text-sm font-semibold leading-6 text-gray-900">
                            จัดการโพรไฟล์ <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
                <div>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="wrapped label tabs example"
                    >
                        <Tab value="get" label="กลุ่มเสี่ยง" />
                        <Tab value="creat" label="บันทึก" />
                    </Tabs>
                    {activeTab === "get" && (
                        <Box className="overflow-hidden w-full mt-10 mb-2 shadow-sm h-[calc(100vh-120px)]">
                            <Typography className="pb-2">ทั้งหมด: {rows.length}</Typography>
                            <DataGrid
                                rows={rows} 
                                columns={columns} 
                                disableRowSelectionOnClick
                                disableDensitySelector
                                hideFooter={true}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        quickFilterProps: {variant: "outlined", size: "small", className:"mr-2 mt-2", InputProps:{className: "font-primary"}},
                                        showQuickFilter: true,
                                        
                                    },
                                }}
                            />
                        </Box>
                    )}
                    {activeTab === "creat" && (
                        <Box className="overflow-hidden w-full mt-10 p-4 shadow-sm h-[calc(100vh-320px)]">
                            <form class="w-full max-w-lg ">
                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                        <TextField id="outlined-basic" label="ชื่อ" variant="outlined" />
                                    </div>
                                    <div class="w-full md:w-1/2 px-3">
                                        <TextField id="outlined-basic" label="นามสกุล" variant="outlined" />
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                        <TextField id="outlined-basic" label="อีเมล์" variant="outlined" />
                                    </div>
                                    <div class="w-full md:w-1/2 px-3">
                                        <TextField id="outlined-basic" label="โทรศัพท์" variant="outlined" />
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3">
                                        <TextField id="outlined-basic" label="วันเกิด" variant="outlined" />
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <TextField id="outlined-basic" label="จังหวัด" variant="outlined" />
                                    </div>
                                    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <TextField id="outlined-basic" label="อำเภอ" variant="outlined" />
                                    
                                    </div>
                                    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                        <TextField id="outlined-basic" label="ตำบล" variant="outlined" />
                                    </div>
                                </div>
                                <Button variant="contained" size="large" onClick={onSubmit}>บันทึก</Button>
                            </form>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className={createSatus? "bg-green-300 opacity-95" : "bg-red-300 opacity-95"}
                            >
                                <DialogTitle id="alert-dialog-title">
                                {createSatus? "บันทึกข้อมูลกลุ่มเสียงสำเร็จ" : "บันทึกข้อมูลกลุ่มเสียงไม่สำเร็จ"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {createSatus? "บันทึกข้อมูลกลุ่มเสียงสำเร็จแล้ว" : "ท่านไม่ได้รับสิทธิ์ให้ทำการบันทึกข้อมูล"}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="contained" onClick={handleClose} autoFocus>
                                        ปิด
                                    </Button>
                                    </DialogActions>
                            </Dialog>
                        </Box>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WelcomePage;