import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import { SettingContext } from "../App";
import env from "react-dotenv";

const UserPermistionPage = () => {
    const { state } = React.useContext(SettingContext);
    const [ title, setTitle ] = React.useState("")
    const [ detail, setDetail ] = React.useState("") 

    const profilePageRedirect = () =>{
        window.location.href = "https://iaamreg.iaam.cloud"
    }

    React.useEffect(() => {
        const profile = state.profile
        if(profile.userApproved !== "true"){
            setTitle("รอการอนุมัติเข้าใช้งาน")
            setDetail(`กรุณารอการอนุมัติจากผู้ดูแลระบบ เมื่อได้รับการอนุมัติ ระบบจะแจ้งไปยังอีเมล "${profile.mail}"`)
        }
        else{
            setTitle("สิทธิการใช้งานถูกจำกัด")
            setDetail(`บัญชีของท่านไม่ได้รับสิทธิให้เข้าใช้งานแอปพลิเคชัน "${env.CLIENT_NAME}" กรุณาติดต่อ Admin`)
        }


    }, [state.profile])

    return (
        <div className="flex justify-center h-screen w-screen bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-red-200 to-red-900">
            <Card className="lg:w-3/6 lg:mt-14 mt-6 w-96 px-4 py-8">
                <CardContent>
                    <Typography variant="h5" color="blue-gray" className="pb-4 font-primary">
                        {title}
                    </Typography>
                    <Typography className="font-primary font-normal">
                        {detail}
                    </Typography>
                </CardContent>
                <CardActions className="pt-0">
                    <Button className="font-primary" onClick={()=> profilePageRedirect()}>รายละเอียด</Button>
                </CardActions>
            </Card>
        </div>
    )
}

export default UserPermistionPage;