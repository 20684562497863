import React from "react";
import { SettingContext } from "../App";
import env from "react-dotenv";

const iaam_conf = {
    op_host: env.IAAM_GATEWAY_HOST,
    iaamgw_host: env.IAAM_GATEWAY_HOST, 
    client_id: env.CLIENT_ID,
    post_logout_uri: env.POST_LOGOUT_URL,
    policyEnforcement: {
        aa_host: env.AA_HOST,
        client_name: env.CLIENT_NAME
    }
}

const iaam = window.iaam;

const IAAMLogin = () => {
    const { dispatch } = React.useContext(SettingContext);
    iaam.initLoginFlow(iaam_conf)
    .then((init) => {
        iaam.isLoggedIn((error, status)=>{
            if(!status){
                iaam.login()
            }
            else{
                iaam.getProfile(async (err, profile) => {  
                    if(!err){
                        iaam.policyEnforcement((perr, policy) => {
                            let permit = false
                            if(!perr){
                                permit = policy.decision === "Permit"? true : false
                            }
                            dispatch({
                                type: "LOGIN",
                                iaam: iaam,
                                profile: profile,
                                user_permit: permit
                            })
                        })                        
                    }
                    else{
                        iaam.login()
                    }
                })
                
            }
        })
    })
    return (<></>)
}

export default IAAMLogin