import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import IAAMLogin from './Pages/IAAMLogin';
import UserPermistionPage from './Pages/UserPermistionPage';
import MainLayout from './Pages/MainLayout';
import NotFoundPage from './Pages/NotFoundPage';
import WelcomePage from './Pages/WelcomePage';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Noto Sans Thai',
        ].join(','),
    }
});

const SettingContext = React.createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            let isUserApproved = false
            const profile = action.profile
                if(profile){
                    // console.log(profile)
                    isUserApproved = profile.userApproved === "true"? true : false
                }

            return {
                ...state,
                isAuthenticated: true,
                isUserApproved: isUserApproved,
                isUserPermitted: action.user_permit,
                profile: profile,
                iaam: action.iaam
            };
        case "LOGOUT":
            return {
                ...state,
                isAuthenticated: false,
                isUserApproved: false,
                setting: null,
                profile: null
            };
        default:
            return state;
    }
};


function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" element={<PageWrapper />} >
                        <Route path="/" element={<WelcomePage />} />
                        <Route path="/*" element={<NotFoundPage />} />
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

function PageWrapper(){
    const initialState = {
        isAuthenticated: false,
    };

    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
        <SettingContext.Provider value={{ state, dispatch }}>
            {!state.isAuthenticated? <IAAMLogin /> : (state.isUserApproved && state.isUserPermitted)? <MainLayout/>: <UserPermistionPage />}
        </SettingContext.Provider>
    );
}
export { SettingContext }

export default App;
